@import 'src/assets/styles/hangry-colors.module';
@import 'src/assets/styles/hangry-constants.module';
@import 'src/assets/styles/hangry-utils.module';
@import 'src/assets/styles/hangry-typographies.module';

.toast {
  position: fixed;
  top: pxToVwMobileMaxed(32px);
  width: 100%;
  max-width: $pageMaxWidth;
  padding: 0 pxToVwMobileMaxed(16px);
  box-sizing: border-box;
  z-index: 99;
  .toastBar {
    width: 100%;
    border-radius: pxToVwMobileMaxed(8px);
    box-shadow: 0 pxToVwMobileMaxed(1px) pxToVwMobileMaxed(10px) rgba(0, 0, 0, 0.05);
    background-color: $milk;
    transition: transform 0.3s;
    display: flex;
    overflow: hidden;

    .leftBar {
      width: pxToVwMobileMaxed(42px);
      color: $milk;
      font-size: pxToVwMobileMaxed(14px);
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $greyLight;
      &.success {
        background-color: $matcha;
      }
      &.error {
        background-color: $sauceLight;
      }
    }

    .message {
      flex: 1;
      padding: pxToVwMobileMaxed(12px) pxToVwMobileMaxed(8px);
      @include caption-medium-12;
      color: $black;
      &.success {
        color: $matcha;
      }
      &.error {
        color: $sauceLight;
      }
    }

    .closeButton {
      margin-right: pxToVwMobileMaxed(12px);
      width: pxToVwMobileMaxed(18px);
      height: pxToVwMobileMaxed(18px);
      color: $grey;
      display: flex;
      align-items: center;
      justify-content: center;
      align-self: center;
      font-size: pxToVwMobileMaxed(14px);
    }

    &.in {
      animation: in 0.3s;
    }
    &.out {
      animation: out 0.3s;
    }
    @keyframes in {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }

    @keyframes out {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  }
}
