@import './assets/styles/hangry-constants.module';
@import './assets/styles/hangry-colors.module';
@import './assets/styles/reset.module';
@import './assets/styles/hangry-typographies.module';
@import './assets/styles/hangry-utils.module';

:root {
  --status-bar-height: 24px;
  --bottom-bar-height: 0;
}

.App {
  box-sizing: border-box;
  max-width: $pageMaxWidth;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.touchable-opacity {
  cursor: pointer;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  transition: 0.3s;
}

.touchable-opacity:active {
  opacity: 0.7;
}

.disable-select {
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

.pincode-input-container {
  .pincode-input-text {
    border: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
    margin-right: pxToVwMobileMaxed(8px) !important;
    background: $greyLighter !important;
    width: pxToVwMobileMaxed(40px) !important;
    height: pxToVwMobileMaxed(40px) !important;
    border-radius: pxToVwMobileMaxed(8px) !important;

    @include title-bold-16;
    color: $black;
    &:focus {
      outline: none;
      box-shadow: none;
    }
    &:disabled {
      background: $greyLightest !important;
    }
  }
}

.CallOtp {
  .pincode-input-container {
    .pincode-input-text {
      margin-right: pxToVwMobileMaxed(4px) !important;
    }
  }
}
